<template>
  <div class="container">
    <!-- 页头 -->
    <content-title :nav="nav"></content-title>
    <!-- <el-input v-model="content"></el-input> -->
    <!-- <el-button>添加假评论</el-button> -->
    <!-- 评论列表 -->
    <h2 class="sec-title">课程评价</h2>
    <el-table :data="tabledata" border style="width: 100%">
      <el-table-column align="center" prop="id" label="序号"> </el-table-column>
      <el-table-column align="center" prop="content" label="发布内容">
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="发布时间">
      </el-table-column>
      <el-table-column align="center" prop="map.userinfo.nick_name" label="发布用户">
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 3" style="color: #66b1ff">待审核</span>
          <span v-else-if="scope.row.status == 1" style="color: #85ce61">通过</span>
          <span v-else-if="scope.row.status == 2" style="color: #f56c6c">拒绝</span>
          <span v-else-if="scope.row.status == 4" style="color: #a6a9ad">违规</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="300px">
        <template slot-scope="scope">
          <div>
            <el-button size="small" v-if="scope.row.status == 3" type="danger" @click="updata(scope.row.id, 2)">拒绝
            </el-button>
            <el-button size='small' v-if="scope.row.status == 3" type="success" @click="updata(scope.row.id, 1)">通过
            </el-button>
            <el-button size="small" type="primary" @click="updata(scope.row.id, 4)" >永久禁言</el-button>
            <el-button size="small" type="primary" @click="updata(scope.row.id, 4)" v-if="scope.row.status == 4">永久禁言</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper" :total="totalNumber"
      class="pagezzss">
      <div></div>
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'InformationReview',
  //信息审核
  data() {
    return {
      nav: {
        firstNav: '内容管理',
        secondNav: '信息审核',
      },
      content: '',
      masterSetPriceEntity: [],
      value: '',
      currentPage: 1,
      totalNumber: 0,
      tabledata: [],
      userID: '',
    }
  },
  created() {
    // 路由传参
    console.log(this.$route.query.userID)
    this.userID = this.$route.query.userID
  },
  mounted() {
    this.Getlist()
  },
  methods: {
    Getlist() {
      let url = '/user/masterComment/queryManagerListPage'
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            is_teach_paypal: true,
          },
        })
        .then((res) => {
          this.tabledata = res.data.data.rows
          this.totalNumber = res.data.data.total
        })
    },
    updata(id, status) {
      let url = '/user/masterComment/update'
      let data = {
        id,
        status,
      }
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            message: '成功',
            type: 'success',
          })
        }else{
            this.$message({
          message: res.data.message,
          type: 'error',
        })
        }

        this.Getlist()
      })
    },

    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.currentPage = val
      this.Getlist()
    },
  },
}
</script>

<style></style>
